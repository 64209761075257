import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  selectedUser: {}
}

export const UsersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      return {
        ...state,
        list: action.payload
      }
    },
    setUserStatus: (state, action) => {
      return {
        ...state,
        list: state.list.map((user) => {
          if (user?.user_name === action.payload.username) {
            return { ...user, enabled: action.payload.enabled }
          }
          return user
        })
      }
    }
  }
})

export const { setUsers, setUserStatus } = UsersSlice.actions

export default UsersSlice.reducer
