import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: []
}

export const ApiKeysSlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    addApiKey: (state, action) => {
      return { 
        ...state,
        list: [...state.list, action.payload]
      }
    },
    removeApiKey: (state, action) => {
      return {
        ...state,
        list: state.list.filter((apiKey) => apiKey.attributes.token !== action.payload)
      }
    },
    setApiKeys: (state, action) => {
      return {
        ...state,
        list: action.payload
      }
    }
  }
})

export const { setApiKeys, addApiKey, removeApiKey } = ApiKeysSlice.actions

export default ApiKeysSlice.reducer
