import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_LANGUAGE } from '@utils/languages'

const DEFAULT_VIDEO_QUALITY = '360p'

const initialAudioInputDevice = () => {
  const item = window.localStorage.getItem('audioInputDevice')
  return item ? JSON.parse(item) : null
}

const initialAudioOutputDevice = () => {
  const item = window.localStorage.getItem('audioOutputDevice')
  return item ? JSON.parse(item) : null
}

const initialVideoInputDevice = () => {
  const item = window.localStorage.getItem('videoInputDevice')
  return item ? JSON.parse(item) : null
}

const initialVideoQuality = () => {
  const item = window.localStorage.getItem('videoQuality')
  return item ? JSON.parse(item) : DEFAULT_VIDEO_QUALITY
}

const initialBackgroundBlur = () => {
  const item = window.localStorage.getItem('backgroundBlur')
  return item ? JSON.parse(item) : null
}

const initialLanguage = () => {
  const item = window.localStorage.getItem('language')
  return item ? JSON.parse(item) : DEFAULT_LANGUAGE.value
}

const initialShowLiveTranscription = () => {
  const item = window.localStorage.getItem('showLiveTranscription')
  return item !== 'false'
}

const initialShowOwnTranscription = () => {
  const item = window.localStorage.getItem('showOwnTranscription')
  return item !== 'false'
}

const initialShowSelfLanguageTranscription = () => {
  const item = window.localStorage.getItem('showSelfLanguageTranscription')
  return item !== 'false'
}

export const meetingConfigSlice = createSlice({
  name: 'meetingConfig',
  initialState: {
    audioInputDevice: initialAudioInputDevice(),
    audioOutputDevice: initialAudioOutputDevice(),
    videoInputDevice: initialVideoInputDevice(),
    videoQuality: initialVideoQuality(),
    backgroundBlur: initialBackgroundBlur(),
    showLiveTranscription: initialShowLiveTranscription(),
    showOwnTranscription: initialShowOwnTranscription(),
    showSelfLanguageTranscription: initialShowSelfLanguageTranscription(),
    language: initialLanguage()
  },
  reducers: {
    changeAudioInputDevice: (state, action) => {
      state.audioInputDevice = action.payload
      window.localStorage.setItem('audioInputDevice', JSON.stringify(action.payload))
    },
    changeAudioOutputDevice: (state, action) => {
      state.audioOutputDevice = action.payload
      window.localStorage.setItem('audioOutputDevice', JSON.stringify(action.payload))
    },
    changeVideoInputDevice: (state, action) => {
      state.videoInputDevice = action.payload
      window.localStorage.setItem('videoInputDevice', JSON.stringify(action.payload))
    },
    changeVideoQuality: (state, action) => {
      state.videoQuality = action.payload
      window.localStorage.setItem('videoQuality', JSON.stringify(action.payload))
    },
    changeBackgroundBlur: (state, action) => {
      state.backgroundBlur = action.payload
      window.localStorage.setItem('backgroundBlur', JSON.stringify(action.payload))
    },
    toggleLiveTranscription: (state, action) => {
      state.showLiveTranscription = action.payload
      window.localStorage.setItem('showLiveTranscription', action.payload)
    },
    toggleOwnTranscription: (state, action) => {
      state.showOwnTranscription = action.payload
      window.localStorage.setItem('showOwnTranscription', action.payload)
    },
    toggleSelfLanguageTranscription: (state, action) => {
      state.showSelfLanguageTranscription = action.payload
      window.localStorage.setItem('showSelfLanguageTranscription', action.payload)
    },
    changeLanguage: (state, action) => {
      state.language = action.payload
      window.localStorage.setItem('language', JSON.stringify(action.payload))
    }
  }
})

export const {
  changeAudioInputDevice,
  changeAudioOutputDevice,
  changeVideoInputDevice,
  changeVideoQuality,
  changeBackgroundBlur,
  changeLanguage,
  toggleLiveTranscription,
  toggleOwnTranscription,
  toggleSelfLanguageTranscription
} = meetingConfigSlice.actions

export default meetingConfigSlice.reducer
