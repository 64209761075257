import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messages: []
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      return { messages: [...state.messages.slice(-50), action.payload] }
    },
    clearChat: () => {
      return { messages: [] }
    }
  }
})

export const { addMessage, clearChat } = chatSlice.actions

export default chatSlice.reducer
