import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isInstalled: false,
  users: []
}

export const HubspotSlice = createSlice({
  name: 'hubspot',
  initialState,
  reducers: {
    setIsInstalled: (state, action) => {
      return {
        ...state,
        isInstalled: action.payload
      }
    },
    setUsers: (state, action) => {
      return {
        ...state,
        users: [...action.payload]
      }
    }
  }
})

export const { setUsers, setIsInstalled } = HubspotSlice.actions

export default HubspotSlice.reducer
