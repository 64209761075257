import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tenantInfo: {},
  userSettings: {},
  usagePlanDetails: {},
  meetingTypes: {}
}

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setAppSettings: (state, action) => {
      return { ...state, ...action.payload} 
    },
    updateUserSettings: (state, action) => {
      return { ...state, userSettings: {...state.userSettings, ...action.payload}}
    }
  }
})

export const { setAppSettings, updateUserSettings} = appConfigSlice.actions

export default appConfigSlice.reducer
