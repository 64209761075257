import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  billingInformation: {
    name: null,
    email: null,
    taxId: null,
    taxNumber: null,
    country: null,
    address: null,
    zipCode: null
  },
  paymentMethods: []
}

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setBillingInformation: (state, action) => {
      return {
        ...state,
        billingInformation: action.payload
      }
    },
    addPaymentMethod: (state, action) => {
      state.paymentMethods.push(action.payload)
    },
    removePaymentMethod: (state, action) => {
      const { id } = action.payload
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter((card) => card.id !== id)
      }
    },
    setPaymentMethods: (state, action) => {
      return {
        ...state,
        paymentMethods: action.payload
      }
    },
    setDefaultPayment: (state, action) => {
      const { id } = action.payload
      return {
        ...state,
        paymentMethods: state.paymentMethods.map((card) => {
          if (card?.id && card.id === id) {
            return { ...card, is_primary: true }
          }
          return { ...card, is_primary: false }
        })
      }
    }
  }
})

export const {
  setBillingInformation,
  addPaymentMethod,
  removePaymentMethod,
  setPaymentMethods,
  setDefaultPayment
} = paymentSlice.actions

export default paymentSlice.reducer
