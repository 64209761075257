import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  transcriptions: [],
  lines: []
}

export const transcriptsSlice = createSlice({
  name: 'transcripts',
  initialState,
  reducers: {
    add: (state, action) => {
      const { user, transcript, isFinal } = action.payload
      const lastIndex = state.lines.findLastIndex((item) => item.user === user)
      const newTranscriptions = []
      const updated = Date.now()
      let newLines = []
  
      if (lastIndex < 0 || state.lines[lastIndex].isFinal) {
        newLines = [...state.lines, {...action.payload, updated}]
      } else {
        newLines = state.lines.map((transcriptionObj, index) => {
          if (transcriptionObj.user === action.payload.user && index === lastIndex) {
            return { ...transcriptionObj, transcript, isFinal, updated: isFinal ? transcriptionObj.updated : updated }
          }
          return {...transcriptionObj}
        })
      }

      newLines.forEach((line, index) => {
        const deconstructedLine = {...line}
        if (index === 0) {
          newTranscriptions.push({...line, transcript: {...line.transcript}, timestampEnd: line.timestamp})
          return
        }
        if (newLines[index - 1].user === deconstructedLine.user) {
          const message = newTranscriptions[newTranscriptions.length - 1]
          Object.keys(message.transcript).map((key) => { message.transcript[key] = `${message.transcript[key]} ${deconstructedLine.transcript[key]}` })
          message.timestampEnd = line.timestamp
          message.updated = line.updated
          return
        }
        newTranscriptions.push({...line, transcript: {...line.transcript}, timestampEnd: line.timestamp})
      })

      newTranscriptions.sort((cur, prev) => {
        if (prev.isFinal || cur.user === prev.user || cur.updated - prev.updated > -2000) return true
        return cur.updated - prev.updated
      })

      const filteredNewLines = newLines.filter((transcription) => {
        return transcription.timestamp >= newTranscriptions.slice(-6)[0].timestamp
      })

      return {
        lines: [...filteredNewLines],
        transcriptions: [...newTranscriptions]
      }
    },
    clear: () => {
      return { lines: [], transcriptions: [] }
    }
  }
})

export const { add, clear } = transcriptsSlice.actions

export default transcriptsSlice.reducer
