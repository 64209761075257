import { createSlice } from '@reduxjs/toolkit'
import { default as Storage } from '@src/@core/storage'

const defaultStage = "published"

const initialState = {
  list: [],
  filteredList: [],
  filters: {
    stage: defaultStage
  },
  nextPage: {}
}

export const JobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    addJob: (state, action) => {
      return { 
        ...state,
        list: [...state.list, action.payload],
        filteredList: [...state.list, action.payload].filter((job) => {
          return state.filters.stage === job.attributes.status
        })
      }
    },
    updateJob: (state, action) => {
      const updatedList = state.list.map((job) => {
        if (job.attributes?.jobId === action.payload.id) {
          return { ...job, attributes: {...job.attributes, ...action.payload }}
        }
        return job
      })

      return { 
        ...state,
        list: updatedList,
        filteredList: updatedList.filter((job) => {
          return state.filters.stage === job.attributes.status
        })
      }
    },
    updateJobStageCount: (state, action) => {
      const {id, stage, operation} = action.payload
      const updatedList = state.list.map((job) => {
        if (job.attributes?.jobId === id) {
          let newValue = job.attributes.stages[stage] 
          if (operation === 'increment') {
            newValue = parseInt(newValue) + 1
          }
          if (operation === 'decrement' && newValue > 0) {
            newValue = parseInt(newValue) - 1
          }
          return {...job, attributes: {...job.attributes, stages: {...job.attributes.stages, [stage]: newValue}}}
        }
        return job
      })

      return { 
        ...state,
        list: updatedList,
        filteredList: updatedList.filter((job) => {
          return state.filters.stage === job.attributes.status
        })
      }
    },
    removeJob: (state, action) => {
      return {
        ...state,
        list: state.list.filter((job) => job.id !== action.payload),
        filteredList: [...state.filteredList].filter((job) => job.id !== action.payload)
      }
    },
    setJob: (state, action) => {
      return {
        ...state,
        list: [action.payload],
        filteredList: [action.payload]
      }
    },
    setJobs: (state, action) => {
      return { 
        ...state,
        list: [...action.payload.data],
        filteredList: [...state.list, ...action.payload.data].filter((job) => {
          return state.filters.stage === "all" || state.filters.stage === job.attributes.status
        }),
        nextPage: action.payload.links.next
      }
    },
    setJobs: (state, action) => {
      return { 
        ...state,
        list: [...state.list, ...action.payload.data],
        filteredList: [...state.list, ...action.payload.data].filter((job) => {
          return state.filters.stage === "all" || state.filters.stage === job.attributes.status
        }),
        nextPage: action.payload.links.next
      }
    },
    clearJobs: (state) => {
      return { 
        ...state,
        list: [],
        filteredList: [],
        nextPage: {}
      }
    },
    setFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload
      }
    },
    updateFilters: (state, action) => {
      const {type, filter} = action.payload
      const newFilters = {...state.filters, ...{[type]: filter}}
      
      Storage.setJobFilters(JSON.stringify(newFilters))
      return { 
        ...state,
        filters: newFilters,
        filteredList: state.list.filter((job) => {
          return newFilters.stage === "all" || newFilters.stage === job.attributes.status
        })
      }
    }
  }
})

export const { setJob, setJobs, addJob, updateJob, updateJobStageCount, removeJob, clearJobs, setFilters, updateFilters } = JobsSlice.actions

export default JobsSlice.reducer
