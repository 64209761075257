// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import sidebar from './sidebar'
import appConfig from './appConfig'
import users from './users'
import contacts from './contacts'
import candidates from './candidates'
import jobs from './jobs'
import payment from './payment'
import invoices from './invoices'
import meetings from './meetings'
import chat from './chat'
import transcripts from './transcripts'
import meetingConfig from './meetingConfig'
import meetingActions from './meetingActions'
import boeChat from './boeChat'
import hubspot from './hubspot'
import integrations from './integrations'
import websocket from './websocket'
import apiKeys from './apiKeys'

const rootReducer = {
  navbar,
  layout,
  sidebar,
  appConfig,
  users,
  contacts,
  candidates,
  jobs,
  payment,
  invoices,
  meetings,
  chat,
  transcripts,
  meetingConfig,
  meetingActions,
  boeChat,
  hubspot,
  integrations,
  websocket,
  apiKeys
}

export default rootReducer
