import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messages: [],
  questions: []
}

export const boeChatSlice = createSlice({
  name: 'boeChat',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      return {
        ...state,
        messages: [...state.messages, action.payload]
      }
    },
    removeMessage: (state, action) => {
      return {
        ...state,
        messages: state.messages.filter((message) => message.id !== action.payload.id)
      }
    },
    addQuestion: (state, action) => {
      const questions = [action.payload, ...state.questions]
      if (questions.length > 3) {
        questions.length = 3
      }
      return { ...state, questions }
    },
    removeQuestion: (state, action) => {
      return {
        ...state,
        questions: state.questions.filter((question) => question !== action.payload)
      }
    },
    clearChat: () => {
      return { messages: [], questions: [] }
    }
  }
})

export const { addMessage, clearChat, addQuestion, removeQuestion, removeMessage } = boeChatSlice.actions

export default boeChatSlice.reducer
