const GUEST_DATA_KEY = 'SmartyMeet.Guest'
const REDIRECT_URL = 'SmartyMeet.RedirectUrl'
const CALENDAR_VIEW = 'SmartyMeet.Calendar.view'
const CALENDAR_DATE = 'SmartyMeet.Calendar.date'
const MEETING_KICKS = 'SmartyMeet.Meeting.kicks'
const HUBSPOT_CODE_KEY = 'hubspot.code'
const JOB_FILTERS = 'SmartyMeet.Job.filters'

/* global localStorage */

// CONFIGURATION
const getConfiguration = () => {
  return {
    apiGatewayUrl: process.env.REACT_APP_POOLED_API_URL,
    appClientId: process.env.REACT_APP_POOL_WEB_CLIENT_ID,
    userPoolId: process.env.REACT_APP_POOL_ID,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    appDomain: process.env.REACT_APP_DOMAIN
  }
}

// GUEST
const clearGuestData = () => localStorage.removeItem(GUEST_DATA_KEY)
const setGuestData = (data) => {
  localStorage.setItem(GUEST_DATA_KEY, data)
}

const getGuestData = () => {
  return localStorage.getItem(GUEST_DATA_KEY)
}

const setRedirectUrl = (data) => {
  localStorage.setItem(REDIRECT_URL, JSON.stringify(data))
}
const getRedirectUrl = () => {
  return JSON.parse(localStorage.getItem(REDIRECT_URL))
}
const clearRedirectUrl = () => localStorage.removeItem(REDIRECT_URL)

// HubSpot
const clearHubSpotCode = () => {
  localStorage.removeItem(HUBSPOT_CODE_KEY)
}
const setHubSpotCode = (code) => {
  localStorage.setItem(HUBSPOT_CODE_KEY, code)
}
const getHubSpotCode = () => {
  return localStorage.getItem(HUBSPOT_CODE_KEY)
}

//CALENDAR
const setCalendarView = (type) => {
  localStorage.setItem(CALENDAR_VIEW, type)
}
const getCalendarView = () => {
  return localStorage.getItem(CALENDAR_VIEW)
}

const setCalendarDate = (date) => {
  localStorage.setItem(CALENDAR_DATE, date)
}

const getCalendarDate = () => {
  return localStorage.getItem(CALENDAR_DATE)
}

//MEETING
const addMeetingKickOut = (id) => {
  const currentKickOuts = JSON.parse(localStorage.getItem(MEETING_KICKS) || '[]')
  localStorage.setItem(MEETING_KICKS, JSON.stringify([...currentKickOuts, id]))
}

const isMeetingKickOut = (id) => {
  const currentKickOuts = JSON.parse(localStorage.getItem(MEETING_KICKS) || '[]')
  return currentKickOuts.includes(id)
}

//JOB
const setJobFilters = (filters) => {
  localStorage.setItem(JOB_FILTERS, filters)
}

const getJobFilters = () => {
  const jobFilters = JSON.parse(localStorage.getItem(JOB_FILTERS) || null)
  return jobFilters
}

export default {
  getConfiguration,
  setGuestData,
  getGuestData,
  clearGuestData,
  setRedirectUrl,
  getRedirectUrl,
  clearRedirectUrl,
  setHubSpotCode,
  getHubSpotCode,
  clearHubSpotCode,
  setCalendarView,
  getCalendarView,
  setCalendarDate,
  getCalendarDate,
  addMeetingKickOut,
  isMeetingKickOut,
  setJobFilters,
  getJobFilters
}
