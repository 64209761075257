import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  triggers: [],
  reactions: [],
  timeline: [],
  translation: {}
}

export const meetingActionsSlice = createSlice({
  name: 'meetingActions',
  initialState,
  reducers: {
    addTrigger: (state, action) => {
      return { ...state, triggers: [...state.triggers, action.payload] }
    },
    addReactionItem: (state, action) => {
      return { ...state, reactions: [...state.reactions, action.payload] }
    },
    addTimelineItem: (state, action) => {
      return { ...state, timeline: [...state.timeline, action.payload] }
    },
    addTranslation: (state, action) => {
      return { ...state, translation: { ...state.translation, ...action.payload } }
    },
    clearActions: () => {
      return { ...initialState }
    }
  }
})

export const {
  addTrigger,
  addReactionItem,
  addTimelineItem,
  addTranslation,
  clearActions
} = meetingActionsSlice.actions

export default meetingActionsSlice.reducer
