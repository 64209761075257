import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: []
}

export const InvoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoices: (state, action) => {
      return {
        ...state,
        list: action.payload
      }
    }
  }
})

export const { setInvoices } = InvoicesSlice.actions

export default InvoicesSlice.reducer
