import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  connections: []
}

export const WebsocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setConnections: (state, action) => {
      return {
        ...state,
        connections: [...action.payload]
      }
    }
  }
})

export const { setConnections } = WebsocketSlice.actions

export default WebsocketSlice.reducer
