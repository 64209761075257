import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: false,
  content: () => <></>,
  title: '',
  data: {},
  id: null
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setContent: (state, action) => {
      return {
        ...state,
        content: action.payload.content
      }
    },
    setTitle: (state, action) => {
      return {
        ...state,
        title: action.payload.title
      }
    },
    setId: (state, action) => {
      return {
        ...state,
        id: action.payload.id
      }
    },
    setUser: (state, action) => {
      return {
        ...state,
        user: action.payload.user
      }
    },
    toggle: (state) => {
      return {
        ...state,
        status: !state.status
      }
    },
    open: (state) => {
      return {
        ...state,
        status: true
      }
    },
    close: (state, action) => {
      let status = false
      if (action?.payload?.id && state.id !== action.payload.id) {
        status = true
      }
      return {
        ...state,
        status
      }
    }
  }
})

export const { toggle, open, close, setTitle, setId, setUser, setContent } = sidebarSlice.actions

export default sidebarSlice.reducer
