import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  apps: []
}

export const IntegrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setApps: (state, action) => {
      return {
        ...state,
        apps: [...action.payload]
      }
    },
    updateAppStatus: (state, action) => {
      const { name, status } = action.payload
      const apps = state.apps.map((app) => {
        if (app.name === name) {
          return { ...app, status }
        }
        return { ...app }
      })
      return {
        ...state,
        apps
      }
    }
  }
})

export const { setApps, updateAppStatus } = IntegrationsSlice.actions

export default IntegrationsSlice.reducer
