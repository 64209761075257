// t("English")
// t("Polish")
// t("Spanish")
// t("German")
// t("Czech")
// t("Hungarian")
// t("Japanese")
// t("Hindi")
// t("Danish")
// t("Finnish")
// t("Dutch")
// t("Italian")
// t("Norwegian")
// t("Ukrainian")
// t("Russian")
// t("Turkish")
// t("French")
// t("Arabic (Qatar)")

const Languages = [
  { value: 'en', label: 'English', flag: 'gb' },
  { value: 'pl', label: 'Polish', flag: 'pl' },
  { value: 'es', label: 'Spanish', flag: 'es' },
  { value: 'fr', label: 'French', flag: 'fr' },
  { value: 'de', label: 'German', flag: 'de' },
  { value: 'cs', label: 'Czech', flag: 'cs' },
  { value: 'ar', label: 'Arabic (Qatar)', flag: 'ar' },
  { value: 'hu', label: 'Hungarian', flag: 'hu' },
  { value: 'ja', label: 'Japanese', flag: 'ja' },
  { value: 'hi', label: 'Hindi', flag: 'hi' },
  { value: 'da', label: 'Danish', flag: 'da' },
  { value: 'nl', label: 'Dutch', flag: 'nl' },
  { value: 'fi', label: 'Finnish', flag: 'fi' },
  { value: 'it', label: 'Italian', flag: 'it' },
  { value: 'el', label: 'Greek', flag: 'el' },
  { value: 'no', label: 'Norwegian', flag: 'no' },
  { value: 'uk', label: 'Ukrainian', flag: 'uk' },
  { value: 'ru', label: 'Russian', flag: 'ru' },
  { value: 'tr', label: 'Turkish', flag: 'tr' }
]

const PlatformLanguages = [
  { value: 'en', label: 'English', flag: 'gb' },
  { value: 'pl', label: 'Polish', flag: 'pl' },
  { value: 'es', label: 'Spanish', flag: 'es' },
  { value: 'de', label: 'German', flag: 'de' }
]

const translateOptions = (t, options) => {
  return options.map((option) => {
    return {...option, label: t(option.label)}
  })
}

const getLanguageLabel = (val) => {
  const language = Languages.find((language) => language.value === val)?.label
  return language ? language : ''
}

const findLanguageOption = (t, val) => {
  return translateOptions(t, Languages).find((language) => language.value === val)
}

const DEFAULT_LANGUAGE = Languages[0]

export {
  Languages,
  PlatformLanguages,
  getLanguageLabel,
  findLanguageOption,
  translateOptions,
  DEFAULT_LANGUAGE
}
