import { createSlice } from '@reduxjs/toolkit'
import _ from "lodash"

// t('General')
// t('Internal Communication')
// t('Offboarding')
// t('Onboarding')
// t('Organizational Culture')
// t('Recruitment & Selection')
// t('Workforce Planning')

const stageFilters = ["new", "in-progress", "closed", "finished"]

const typeMap = {
  category: 'categoryFilters',
  stage: 'stageFilters'
}

const initialState = {
  list: [],
  filterList: {
    categoryFilters: [],
    stageFilters: []
  },
  filteredList: [],
  filters: {
    categoryFilters: [],
    stageFilters: []
  }
}

const parseCategoryToFilter = (category) => {
  return _.replace(`${_.toLower(category)}`, RegExp(' ', 'g'), '-').replace('&', 'and')
}

export const meetingsSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    addMeeting: (state, action) => {
      return { 
        ...state,
        list: [...state.list, action.payload],
        filteredList: [...state.list, action.payload].filter((meeting) => {
          return (
            state.filters.categoryFilters.includes(parseCategoryToFilter(meeting.category)) &&
            state.filters.stageFilters.includes(meeting.stage)
          )
        })
      }
    },
    setMeeting: (state, action) => {
      const { id } = action.payload
      const findMeeting = state.list.findIndex(el => el.id === id)
      if (findMeeting !== -1) {
        const list = state.list.map((meeting) => {
          if (meeting.id === id) {
            return { ...meeting, ...action.payload }
          }
          return { ...meeting }
        })

        return {
          ...state,
          list,
          filteredList: list.filter((meeting) => {
            return (
              state.filters.categoryFilters.includes(parseCategoryToFilter(meeting.category)) &&
              state.filters.stageFilters.includes(meeting.stage)
            )
          })
        }
      }
      return {
        ...state,
        list: [...state.list, action.payload],
        filteredList: [...state.list, action.payload].filter((meeting) => {
          return (
            state.filters.categoryFilters.includes(parseCategoryToFilter(meeting.category)) &&
            state.filters.stageFilters.includes(meeting.stage)
          )
        })
      }
    },
    removeMeeting: (state, action) => {
      const { id } = action.payload
      return {
        ...state,
        list: state.list.filter((meeting) => meeting.id !== id),
        filteredList: [...state.filteredList].filter((meeting) => meeting.id !== id)
      }


    },
    setMeetings: (state, action) => {
      return {
        ...state,
        list: action.payload,
        filteredList: action.payload.filter((meeting) => {
          return (
            state.filters.categoryFilters.includes(parseCategoryToFilter(meeting.category)) &&
            state.filters.stageFilters.includes(meeting.stage)
          )
        })
      }
    },
    setFilters: (state, action) => {
      const categoryFilters = action.payload.map(item => parseCategoryToFilter(item["type"]))
      return {
        ...state,
        filterList: {
          categoryFilters,
          stageFilters
        },
        filters: {
          categoryFilters,
          stageFilters
        }
      }
    },
    updateFilters: (state, action) => {
      let newFilters
      const {type, filter} = action.payload
      if (!state.filters[typeMap[type]].includes(filter)) {
        newFilters = {...state.filters, ...{[typeMap[type]]: [...state.filters[typeMap[type]], filter]}}
      } else {
        newFilters = {...state.filters, ...{[typeMap[type]]: state.filters[typeMap[type]].filter(e => e !== filter)}}
      }      
      return { 
        ...state,
        filters: newFilters,
        filteredList: state.list.filter((meeting) => {
          return (
            newFilters.categoryFilters.includes(parseCategoryToFilter(meeting.category)) &&
            newFilters.stageFilters.includes(meeting.stage)
          )
        })
      }
    },
    clearFilters: (state, action) => {
      const {type} = action.payload
      const list = [typeMap[type]]

      let newFilters = {...state.filters, ...{[typeMap[type]]: []}}
      if ([...state.filters[list]].length === 0 || [...state.filters[list]].length !== state.filterList[list].length) {
        newFilters = {...state.filters, ...{[typeMap[type]]: state.filterList[list]}}
      }

      return { 
        ...state,
        filters: newFilters,
        filteredList: state.list.filter((meeting) => {
          return (
            newFilters.categoryFilters.includes(parseCategoryToFilter(meeting.category)) &&
            newFilters.stageFilters.includes(meeting.stage)
          )
        })
      }
    }
  }
})

export const { addMeeting, setMeeting, setMeetings, removeMeeting, setFilters, updateFilters, clearFilters} = meetingsSlice.actions

export default meetingsSlice.reducer
